@import '../variables';
@import '../mixin';

.page-content.search {
	.search-results {
		.filters-header {
			height: 60px;
			margin-bottom: 14px;
			border: solid 0px #eee;
			border-radius: 6px;
			padding: 12px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.filters-header-shipping-wrapper {
				button {
					width: 36px;
					height: 36px;
					padding: 0px;
					text-align: center;
					overflow: hidden;
					transition: none;

					.deliv, .take {
						top: -11px;
					}

					span {
						display: inline-block;
						transition: none;
					}

					.expanded {
						font-size: 11px;
						visibility: hidden;
						display: none;
					}

					&:hover, &.active {
						width: 145px;
						text-align: left;
						padding-left: 6px;

						.deliv, .take {
							top: -11px;
						}

						.expanded {
							width: 120px;
							padding-left: 3px;
							visibility: visible;
							display: inline;
						}
					}

					&::after {
						content: "";
						display: block;
						clear: both;
					}
				}

				div {
					display: inline-block;
				}

				.take-modified {
					&::before {
						font-size: 20px !important;
						top: -3px !important;
					}
				}

				.deliv-modified {
					&::before {
						font-size: 23px !important;
						left: 3px !important;
					}
				}
			}

			.filters-header-food-type-wrapper, .filters-header-shipping-wrapper {
				& button {
					margin-left: 4px;
					background-color: #eee;
					border-color: #eee;
					color: $body-text-color;
					font-weight: 500;

					&.active, &:hover {
						background-color: $color-secondary;
						border-color: $color-secondary;
						color: #fff;

						& span {
							color: white !important;
						}
					}
				}

				& span {
					transition: all 0.3s ease-in-out
				}
			}
		}

		.no-results {
			text-align: center;
			padding: 80px 0px 160px;

			.label {
				font-size: 15px;
				color: $body-text-color;
			}

			.image {
				margin: 35px 0px;

				img {
					width: 145px;
				}
			}
		}
	}

	.display-control {
		overflow: hidden;
		height: 0;
		opacity: 0;
		transition: height 0ms 400ms, opacity 400ms 0ms;

		&.visible {
			height: auto;
			opacity: 1;
			transition: height 0ms 400ms, opacity 600ms 0ms;
		}
	}

	.product-card {
		.delivery-options {
			position: absolute;
			bottom: 5px;
			left: 0px;

			.delivery, .carryout {
				z-index: 100;
				position: relative;

				.custom-tooltip {
					visibility: hidden;
					position: absolute;
					right: -130px;
					top: -4px;
					opacity: 1;
					width: 120px;
					background: rgba(0, 0, 0, 0.7);
					color: rgb(232, 230, 227);
					text-align: left;
					padding: 4px 8px;
					border-radius: 3px;
					z-index: 99;
					transition: opacity .6s;
					font-size: 11px;

					// &::after {
					//     content: "";
					//     position: absolute;
					//     top: 100%;
					//     left: 50%;
					//     margin-left: -5px;
					//     border-width: 5px;
					//     border-style: solid;
					// }
				}

				.take, .deliv {
					z-index: 100;
					display: block;
					height: 26px;
					width: 30px;
					left: 15px;

					&::before {
						color: #fff;
						opacity: 0.4;
					}

					&.take {
						left: 18px;
					}
				}

				&:hover {
					.custom-tooltip {
						visibility: visible;
						opacity: 1;
					}

					.take, .deliv {
						&::before {
							opacity: 1 !important;
						}
					}
				}
			}
		}

		&:hover {
			.delivery-options {
				.delivery, .carryout {
					.take, .deliv {
						&::before {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	.btn_map {
		display: block;
		background: #ccc url(../../img/map_bt_bg.png) center no-repeat;
		margin-bottom: 15px;
		height: 60px;
		border: none;
		overflow: hidden;
		@include border-radius(6px);

		&.desktop {
			width: 100%;
		}

		&.mobile {
			height: 42px;
			display: none;
		}

		span {
			background-color: $color-4;
			color:#fff;
			padding: 5px 10px;
			font-weight: 500;
			@include fontSize(13px);
			@include border-radius(3px);
		}

		@include tablet {
			display: none !important;

			&.mobile {
				display: block !important;
			}
		}
	}




	/* Filters Masonry */
	.switch-field {
		overflow: hidden;
		border: 1px solid rgba(0,0,0,0.08);
		@include border-radius(3px);
		float: left;
		width: auto;
		margin-top: 5px;
		input {
			position: absolute!important;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			width: 1px;
			border: 0;
			overflow: hidden;
			&:checked+label {
				@include box-shadow (none);
				color: rgba(0,0,0,0.9);
			}
		}
		label {
			float: left;
			display: inline-block;
			min-width: 70px;
			color: rgba(0,0,0,.5);
			font-weight: 600;
			@include fontSize(12px);
			text-align: center;
			text-shadow: none;
			padding: 10px 12px 8px 12px;
			line-height: 1;
			border-right: 1px solid rgba(0,0,0,0.08);
			@include transition-default (all,0.2s,ease-in-out);
			@include extra-small-mobile {min-width: inherit;}
			margin: 0;
			&:first-of-type {
				border-left: 0;
			}
			&:last-of-type {
				border-right: 0;
			}
			&:hover {
				cursor: pointer;
				color: $color-1;
			}
		}
	}

	/* Lsiting Map */
	.map_view {
		.page_header {
			margin: 0 -15px 0 -15px;
			padding: 20px 15px;
			 &.sticky {
				 @include mobile { margin: 0;}
			}
			h1 {
				margin-bottom: 10px;
				@include fontSize(16px);
			}
		}
		.margin_detail {
			padding-top: 10px;
			margin: 0px -15px 0 -15px;
		}
	}

	.full-height {
		height: 100%;
		@include tablet {
			height: auto;
		}
	}

	.row-height {
		height: 100%;
		min-height: 100%;
		padding-top: 58px;
		@include tablet {
			height: auto;
			padding-top: 48px;
		}
	}

	.map-right {
		height: 100%;
		min-height: 100%;
		padding: 0px;
		position: fixed;
		top:0;
		right: 0;
		z-index: 0;
		z-index: 9999;
		@include tablet {
			height: 400px;
			position: static;
			min-height: inherit;
		}
	}

	.map_right_listing {
		height: 100%;
		width: 100%;
	}

	.content-left {
		height: 100%;
		min-height: 100%;
		overflow-y: scroll;
		background-color: $body-color;
		@include tablet {
			padding-top: 0;
		}
	}

	a.address {
		display: inline-block;
		font-weight: 500;
		color: #999;
		line-height: 1;
		&:before {
			font-family: 'ElegantIcons';
			content: "\e081";
			margin-right: 5px;
			display: inline-block;
		}
		&:hover {
			color: $color-1;
		}
		@include tablet {
			display: none;
		}
	}

	/*============================================================================================*/
	/* 1.5  RangeSlider */
	/*============================================================================================*/
	.distance {
		margin-bottom: 15px;
	}
	.rangeslider,
	.rangeslider__fill {
	  display: block;
	  -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
	  -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
	  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
	  -moz-border-radius: 10px;
	  -webkit-border-radius: 10px;
	  border-radius: 10px;
	}

	.rangeslider {
	  background: #e6e6e6;
	  position: relative;
	}

	.rangeslider--horizontal {
	  height: 5px;
	  width: 100%;
	}

	.rangeslider--vertical {
	  width: 20px;
	  min-height: 150px;
	  max-height: 100%;
	}

	.rangeslider--disabled {
	  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	  opacity: 0.4;
	}

	.rangeslider__fill {
	  background: $color-1;
	  position: absolute;
	}
	.rangeslider--horizontal .rangeslider__fill {
	  top: 0;
	  height: 100%;
	}
	.rangeslider--vertical .rangeslider__fill {
	  bottom: 0;
	  width: 100%;
	}

	.rangeslider__handle {
	  background: white;
	  border: 2px solid $color-1;
	  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
	  cursor: pointer;
	  display: inline-block;
	  width: 20px;
	  height: 20px;
	  position: absolute;
	  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
	  background-size: 100%;
	  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	  -moz-border-radius: 50%;
	  -webkit-border-radius: 50%;
	  border-radius: 50%;
	}
	.rangeslider__handle:after {
	  content: "";
	  display: block;
	  width: 18px;
	  height: 18px;
	  margin: auto;
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
	  background-size: 100%;
	  -moz-border-radius: 50%;
	  -webkit-border-radius: 50%;
	  border-radius: 50%;
	}
	.rangeslider__handle:active, .rangeslider--active .rangeslider__handle {
	  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
	  background-size: 100%;
	}
	.rangeslider--horizontal .rangeslider__handle {
	  top: -8px;
	  touch-action: pan-y;
	  -ms-touch-action: pan-y;
	}
	.rangeslider--vertical .rangeslider__handle {
	  left: -10px;
	  touch-action: pan-x;
	  -ms-touch-action: pan-x;
	}

	input[type="range"]:focus + .rangeslider .rangeslider__handle {
	  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
	  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
	  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
	}

	/*-------- Map styles --------*/

	/* Cluster styles */
	.cluster img {display:none!important;}

	.cluster-visible {
		text-align: center;
		font-size: 15px !important;
		color: #fff !important;
		font-weight: 500;
		border-radius: 50%;
		width: 36px !important;
		height: 36px !important;
		line-height: 36px !important;
		background-color: $color-1!important;
		background-image: none!important;
	}

	.cluster-visible:before {
		border: 7px solid $color-1;
		opacity: 0.2;
		box-shadow: inset 0 0 0 4px $color-1;
		content: '';
		position:absolute;
		border-radius: 50%;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: cluster-animation 2.5s infinite;
	}

	@keyframes cluster-animation {
		0%,100% {
			transform:scale(1.3) rotate(0deg)
		}
		50% {
			transform:scale(1.5) rotate(90deg)
		}
	}

	.map {
		width: 100%;
		height: 500px;
		&.map_single {
			height: 400px;
		}
	}
	.infoBox {
		-webkit-animation: fadeIn 0.9s;
		animation: fadeIn 0.9s;
		//padding-right: 50px;
		> img {
			position: absolute!important;
			right: 60px!important;
			top: 10px!important;
			z-index: 9999;
			width: 20px;
			height: 20px;
			display: block;
			cursor: pointer;
		}
	}

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
		   opacity: 1;
		}
	}

	.gm-style {
		.gm-style-iw-c {
			padding: 0px !important;

			.gm-style-iw-d {
				overflow: auto !important;
			}

		}

		.gm-style-iw-tc {
			&::after {
				background: #eee;
			}
		}

		button.gm-ui-hover-effect {
			visibility: hidden;
		}
	}

	.marker_info {
		width: 240px;
		//height: 270px;
		border-radius: 5px;
		text-align: left;
		background: #000;
		background: rgba(255, 255, 255, 1);
		position: relative;
		z-index: 999;
		// font-family: "Poppins", Helvetica, sans-serif;

		.logo-container {
			margin: 0px 0px 12px 15px;
			height: 150px;
			min-height: 150px;
			min-width: 150px;

			.logo {
				margin: auto;
				border-radius: 0px;
				max-width: 150px;
				max-height: 150px;
				padding: 0px !important;
			}
		}

		.padded {
			padding: 12px 20px;
		}

		.with-close {
			overflow: hidden;

			.logo-container,
			.close {
				padding-right: 15px;
			}
		}

		.gray {
			background-color: #eee;
			position: relative;
		}

		.close {
			position: absolute;
			top: 8px;
			right: -5px;
			cursor: pointer;
		}

		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
		img {
			display: block;
			@include border-radius(5px 5px 0 0);
		}
		h3 {
			@include fontSize(16px);
			line-height: 1.1;
			font-weight: 500;
			margin-top: 3px;
			color: #444;
		}
		em {
			display: inline-block;
			@include fontSize(12px);
			color: #999;
			font-style: normal;
		}
		span {
			display: block;
			padding: 15px 20px 0 20px;
			@include fontSize(13px);
			line-height: 1.2;
			color: #fff;
			position: relative;
			strong {
				display: block;
				font-weight: 500;

			}
		}
		&:after{
			right: 100%;
			top: 56%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(0, 0, 0, 0);
			border-right-color: rgba(255, 255, 255, 1);
			border-width: 12px;
			margin-top: -12px;
		}
	}
	a.btn_infobox_detail{
		position:absolute;
		top:20px;
		right:15px;
		width:20px;
		height:20px;
	}
	a.btn_infobox_detail:before, .btn_infobox_get_directions:before,a.btn_infobox_phone:before{
		 font-style: normal;
		  font-weight: normal;
		  font-family: "ElegantIcons";
		  @include fontSize(20px);
		  cursor:pointer;
	}
	a.btn_infobox_detail:before{
	  content: "\70";
	  color:#ccc;
		&:hover {
		  color: $color-1;
		}
	}
	.btn_infobox_get_directions, a.btn_infobox_phone{
		border:none;
		display:inline-block;
		font-weight:500;
		color: $color-1;
		background:none;
		cursor:pointer;
		@include fontSize(13px);
		position:relative;
		padding-left:20px;
		margin-bottom:10px;
		outline:none;
		&:focus {
		  outline:none;
		}
	}
	.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover{color:#333;}
	.btn_infobox_get_directions:before{
	  @include fontSize(14px);
	  content: "\e080";
	  position:absolute;
	  left:0;
	  top:0;
	}
	a.btn_infobox_phone:before{
	  font-size:14px;
	  content: "\e090";
	  position:absolute;
	  left:0;
	  top:-2px;
	}
	span.infobox_rate{
		display:inline-block;
		margin:-44px 0 0 -20px;
		float:left;
		background-color:$color-2;
		padding:5px 8px;
		font-weight:500;
		text-align:left;
		@include fontSize(12px);
	}

	@media (max-width: $small-device-width) {
		.filters-header-shipping-wrapper {
			position: absolute;
			top: -58px;
			left: 62px;
		}

		.filters-header-food-type-wrapper {
			text-align: center;
			margin: auto;
		}
	}

	@media (min-width: $large-device-width) {
		.product-card {

			&:not(:hover) {
				picture {
					img {
						filter: grayscale(50%);
					}
				}

				.actions {
					.not-added-container {
						button {
							background-color: transparent;
							border-color: transparent;
							color: $color-text;
						}
					}
				}
			}
		}
	}

	.search-address {
		align-items: center;
		color: $color-text;
		padding-top: 10px;

		.where {
			color: #111;
		}

		.pick-address {
			font-size: 12px;
			color: $color-primary;
			cursor: pointer;
			margin-left: 10px;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}